import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import {
  removeHTMLTags,
  generatePostURI,
} from '../components/common/functions';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  CONTAINED,
  COLOR_GREEN,
  screen,
  Button,
  StyledLink,
  COLOR_TEXT_GRAY,
} from '../components/common/variables';
import { IoIosSearch } from 'react-icons/io';

const Wrapper = styled.div`
  ${CONTAINED}
  min-height: 287px;
  padding-bottom: 100px;
  .margin {
    margin: 1rem 1.5rem;
    @media ${screen.medium} {
      margin: 2rem 2.5rem;
    }
  }

  .products-heading-text {
    color: #3a3a3a;
    margin: 16px 0px 0 0;
    padding: 0;
    font-weight: 400;
    font-family: 'Hero', Arial, Helvetica, sans-serif;
  }

  .articles-heading-text {
    color: #3a3a3a;
    margin: 0 0 8px 0;
    padding: 0;
    font-weight: 400;
    font-family: 'Hero', Arial, Helvetica, sans-serif;
  }

  .divider {
    background-color: #808080;
    width: 100%;
    height: 1px;
    margin: 1.5rem 0rem;
  }
`;

const MetaSection = styled.div`
  h1 {
    font-size: 1.8rem;
    color: ${COLOR_GREEN};
    margin-top: 54px;
    font-weight: 400;
    margin-bottom: 0.4rem;
    @media ${screen.largeXL} {
      margin-top: 90px;
      font-size: 2.2rem;
    }
  }
  p {
    line-height: 16px;
    margin-top: 1rem;
    span {
      font-weight: 500;
    }
  }
`;

const ResultWrapper = styled.div`
  display: flex;
  /* justify-content: ${(props) =>
    props.size < 4 ? 'flex-start' : 'space-between'}; */
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media ${screen.small} {
    justify-content: flex-start;
  }

  .product-title {
    color: ${COLOR_GREEN};
    max-width: 300px;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    text-align: center;
  }
`;

const ContentItemWrapper = styled.div`
  font-size: 14px;
  line-height: 15px;
  flex: 0 0 44%;
  max-width: 280px;
  margin: 1rem 1rem 1rem 0rem;
  @media ${screen.medium} {
    flex: 0 0 22%;
  }
`;

const SearchForm = styled.form`
  width: 100%;
  display: flex;
  position: relative;
  align-content: center;
  margin-bottom: 1rem;
  @media ${screen.large} {
    width: 450px;
  }

  .search-icon {
    position: absolute;
    right: 75px;
    top: 30px;
  }
  .search {
    border: 0;
    outline: none;
    background: #e7e7e7;
    box-sizing: border-box;
    height: 45px;
    border-radius: 10px;
    width: 85%;
    display: inline-block;
    padding: 13px 52px 13px 20px;
    text-overflow: ellipsis;
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

const BlogPost = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  @media ${screen.medium} {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .title {
    color: ${COLOR_GREEN};
    font-weight: 400;
    margin: 1rem 0 0.8rem 0;
    font-family: 'Hero', Arial, Helvetica, sans-serif;
    transition: all 0.28s ease-out;
    max-width: 600px;
    font-size: 1.65rem;
    margin-top: 0;
    line-height: 1.15;
  }

  .title:hover {
    color: #3a3a3a;
  }

  .content {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 25px;
    color: #808080;

    .excerpt {
      color: gray;
      margin: 1rem 0 0.8rem 0;
      font-size: 0.9rem;
    }
  }

  .image {
    max-width: 300px;
    width: 100%;
    margin-bottom: 20px;
    @media ${screen.medium} {
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    @media ${screen.medium} {
      width: 50%;
    }
  }

  .search-blog-date {
    color: #808080;
    font-weight: 100;
    font-size: 0.87rem;
    opacity: 0.8;
  }

  .blog-link {
    width: 74%;
    margin: 0 auto;
    text-align: center;
    @media ${screen.small} {
      width: auto;
      margin: 0;
    }
  }
`;

const NoResults = styled.div`
  h1 {
    font-size: 1.2rem;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 400;
  }
  color: ${COLOR_TEXT_GRAY};
  .call {
    width: 100%;
    @media ${screen.large} {
      width: 300px;
    }
  }

  .description {
    @media ${screen.large} {
      line-height: 16px;
    }
    span {
      font-weight: 600;
    }
    button {
      margin-top: 30px;
    }
  }
`;

class NotFoundPage extends Component {
  state = {
    query: '',
  };

  componentDidMount() {
    this.checkQuery();
  }

  componentWillReceiveProps() {
    this.checkQuery();
  }

  checkQuery() {
    const queryString = new URLSearchParams(window.location.search);
    const query = queryString.get('query');

    this.setState({ query: query || '' });
  }

  renderItem = (o, i) => {
    let content;
    if (['Product', 'CompostAPakProduct'].includes(o.nodeType)) {
      if (o.nodeType === 'CompostAPakProduct') {
        content = (
          <Link to={generatePostURI(o) || '/'}>
            <GatsbyImage
              image={
                o.acfCompostAPakProduct.mainImages.find(
                  (i) => i.image && i.image.localFile
                ).image.localFile.childImageSharp.gatsbyImageData
              }
              alt="c"
            />

            <div className="product-title">{o.title}</div>
          </Link>
        );
      } else {
        content = (
          <Link to={generatePostURI(o) || '/'}>
            <GatsbyImage
              image={
                o.acfProduct.mainImages.find(
                  (i) => i.image && i.image.localFile
                ).image.localFile.childImageSharp.gatsbyImageData
              }
              alt="c"
            />

            <div className="product-title">{o.title}</div>
          </Link>
        );
      }

      return <ContentItemWrapper key={i}>{content}</ContentItemWrapper>;
    }

    if (o.nodeType === 'Post') {
      content = (
        <BlogPost>
          <div className="image">
            <Link to={generatePostURI(o) || '/'}>
              <GatsbyImage
                image={
                  o.acfBlog.featuredImage.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt="c"
              />
            </Link>
          </div>

          <div className="content">
            <Link to={generatePostURI(o) || '/'}>
              <div
                className="title"
                dangerouslySetInnerHTML={{ __html: o.title }}
              />
            </Link>
            <div className="search-blog-date">{o._date}</div>
            <div
              className="excerpt"
              dangerouslySetInnerHTML={{
                __html:
                  removeHTMLTags(o.acfBlog.content)
                    .split(' ')
                    .slice(0, 30)
                    .join(' ') + '...',
              }}
            />
            <div className="button-container">
              <StyledLink className="blog-link" to={generatePostURI(o) || '/'}>
                Read more
              </StyledLink>
            </div>
          </div>
        </BlogPost>
      );
      return <div key={i}>{content}</div>;
    }
    return <div key={i}>content</div>;
  };

  recursiveIteration = (object) => {
    for (const property in object) {
      if (typeof object[property] !== 'string') continue;

      const value = object[property]
        .toString()
        .toUpperCase()
        .replace(/[^a-z0-9A-Z]/gim, '')
        .replace(/\s+/g, ' ');

      const query =
        this.props.query ||
        this.state.query
          .toUpperCase()
          .replace(/[^a-z0-9A-Z]/gim, '')
          .replace(/\s+/g, ' ');

      if (value.includes(query)) {
        return true;
      }
    }
  };

  sortBestMatch = (a, b) => {
    return this.getMatches(b) - this.getMatches(a);
  };

  getMatches = (object) => {
    let matches = 0;
    for (const property in object) {
      if (typeof object[property] !== 'string') continue;

      const value = object[property]
        .toString()
        .toUpperCase()
        .replace(/[^a-z0-9A-Z]/gim, '')
        .replace(/\s+/g, ' ');

      const query = this.state.query
        .toUpperCase()
        .replace(/[^a-z0-9A-Z]/gim, '')
        .replace(/\s+/g, ' ');

      if (value.includes(query)) {
        matches++;
      }
    }
    return matches;
  };
  render() {
    const { data } = this.props;
    const phone = '1300 739 913';
    const allArticles = this.props.data.blogPosts.edges.reduce((a, c, i) => {
      // changing the date format in the original blog list
      c.node._date = moment(c.node.date).format('Do MMM YYYY');
      a[i] = c;
      return a;
    }, []);

    const searchable = [
      ...data.cpProducts.edges.map((e) => e.node),
      ...data.products.edges.map((e) => e.node),
      ...allArticles.map((e) => e.node),
    ];
    const results = searchable
      .filter(this.recursiveIteration)
      .sort(this.sortBestMatch)
      .filter((o) => {
        if (['Product', 'CompostAPakProduct'].includes(o.nodeType)) {
          if (o.nodeType === 'CompostAPakProduct') {
            if (
              !o.acfCompostAPakProduct.mainImages.find(
                (i) => i.image && i.image.localFile
              ) ||
              !o.acfCompostAPakProduct.mainImages.find(
                (i) => i.image && i.image.localFile
              ).image
            ) {
              console.error(
                `[Alert] [Product] ${o.title} is missing main_images.find(i => i.image && i.image.localFile)`
              );
              return false;
            }
          } else {
            if (
              !o.acfProduct.mainImages.find(
                (i) => i.image && i.image.localFile
              ) ||
              !o.acfProduct.mainImages.find((i) => i.image && i.image.localFile)
                .image
            ) {
              console.error(
                `[Alert] [Product] ${o.title} is missing main_images.find(i => i.image && i.image.localFile)`
              );
              return false;
            }
          }
        } else {
          if (!o.acfBlog.featuredImage || !o.acfBlog.featuredImage.localFile) {
            console.error(
              `[Alert] [Blog] ${o.title} is missing featured_image`
            );
            return false;
          }
        }
        return true;
      });

    const allProducts = results.filter((r) =>
      ['Product', 'CompostAPakProduct'].includes(r.nodeType)
    );
    // Filter out a product by wordpress post id coz of duplication of 1 specific product.
    const productResults = allProducts.filter((product) => product.id !== 2416);
    const articleResults = results.filter((r) => r.nodeType === 'Post');

    return (
      <Layout>
        <SEO title="Search Results" />
        <Wrapper>
          <div className="margin">
            <MetaSection>
              <h1>Search Results</h1>
              {this.state.query.length > 2 && (
                <p>
                  Your search for '<span>{this.state.query.trim()}</span>'
                  produced {results.length} results.
                </p>
              )}
              <SearchForm>
                <IoIosSearch className="search-icon" />
                <input
                  placeholder="Search.."
                  className="search"
                  type="query"
                  name="query"
                  value={this.state.query}
                  onChange={({ target }) =>
                    this.setState({
                      query: target.value,
                    })
                  }
                />
              </SearchForm>
            </MetaSection>

            {this.state.query.length > 2 && results.length > 0 ? (
              <Fragment>
                {productResults.length > 0 && <div className="divider" />}
                {productResults.length > 0 && (
                  <Fragment>
                    <h3 className="products-heading-text">Products</h3>

                    <ResultWrapper size={productResults.length}>
                      {productResults.map((o, i) => this.renderItem(o, i))}
                    </ResultWrapper>
                  </Fragment>
                )}
                {articleResults.length > 0 && (
                  <Fragment>
                    <div className="divider" />
                    <h3 className="articles-heading-text">Articles</h3>
                    <ResultWrapper>
                      {articleResults.map((o, i) => this.renderItem(o, i))}
                    </ResultWrapper>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              this.state.query.length > 2 && (
                <NoResults>
                  <h1>Sorry, no results found!</h1>
                  <p className="description">
                    We don't seem to be able to find anything that matches your
                    search for '<span>{this.state.query.trim()}</span>
                    '
                    <br />
                    <br />
                    If you want to chat with one of our team members, get in
                    touch!
                    <br />
                    <br />
                    <a href={`tel:${phone}`}>
                      <Button className="call">Call {phone}</Button>
                    </a>
                  </p>
                </NoResults>
              )
            )}
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const data = graphql`
  {
    cpProducts: allWpCompostAPakProduct {
      edges {
        node {
          id
          slug
          nodeType
          title
          acfCompostAPakProduct {
            mainImages {
              productCode
              image {
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 900)
                  }
                }
              }
            }
          }
        }
      }
    }

    products: allWpProduct {
      edges {
        node {
          id
          title
          slug
          nodeType
          acfProduct {
            desc
            mainImages {
              productCode
              image {
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 900)
                  }
                }
              }
            }
          }
        }
      }
    }

    blogPosts: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          nodeType
          date
          acfBlog {
            content
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;
